import axios from '@/plugins/axios'
import gerarQueryString from '@/utils/gerar-query-string'

export default {

  async estatisticas (dados) {
    const parametros = gerarQueryString(dados)
    return await axios.get(`relatorios/leituras/estatisticas?${parametros}`, { timeout: 120000 })
  },

  async leiturasSemPlaca (dados) {
    const parametros = gerarQueryString(dados)
    return await axios.get(`relatorios/leituras-sem-placa?${parametros}`)
  },

  async leiturasPlacaCorrigida (dados) {
    const parametros = gerarQueryString(dados)
    return await axios.get(`relatorios/leituras-placa-corrigida?${parametros}`)
  },

  async analiseProximidade (dados) {
    return await axios.post(`perfil-veiculo/analise-proximidade`, dados)
  },
}
