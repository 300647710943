<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-sheet
          color="white"
          class="pa-3"
          tile
          elevation="1"
        >
          <v-row>
            <v-col
              cols="6"
              md="2"
            >
              <v-menu
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataInicioFormatada"
                    v-mask="'##/##/####'"
                    label="Data Início"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_inicio = formatarData.api(dataInicioFormatada)"
                    @click:append="menuDataInicio = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_inicio"
                  no-title
                  class="ma-0"
                  @input="menuDataInicio = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="pl-md-0"
            >
              <v-menu
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataFimFormatada"
                    v-mask="'##/##/####'"
                    label="Data Fim"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_fim = formatarData.api(dataFimFormatada)"
                    @click:append="menuDataFim = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_fim"
                  no-title
                  class="ma-0"
                  @input="menuDataFim = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="ml-md-2 pt-0 pl-md-0 pt-md-2"
            >
              <v-btn
                color="info"
                class="mt-1"
                :loading="carregando"
                @click="carregarRelatorio()"
              >
                <v-icon
                  left
                  v-text="'mdi-magnify'"
                />
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            :loading="carregando"
            :headers="cabecalho"
            :items="linhas"
            :hide-default-footer="true"
            :items-per-page="350"
            loading-text="Buscando dados... Períodos maiores que 10 dias podem levar mais tempo para carregar."
            :sort-by="cabecalho[0].value"
          >
            <template v-slot:item.alertas="{ item }">
              <span>{{ Number(item.alertas).toLocaleString() }}</span>
            </template>
            <template v-slot:item.leiturasCorrigidas="{ item }">
              <span>{{ Number(item.leiturasCorrigidas).toLocaleString() }}</span>
            </template>
            <template v-slot:item.imagens="{ item }">
              <span>{{ Number(item.imagens).toLocaleString() }}</span>
            </template>
            <template v-slot:item.imagensSemOCR="{ item }">
              <span>{{ Number(item.imagensSemOCR).toLocaleString() }}</span>
            </template>
            <template v-slot:item.leituras="{ item }">
              <span>{{ Number(item.leituras).toLocaleString() }}</span>
            </template>
            <template v-slot:item.placas="{ item }">
              <span>{{ Number(item.placas).toLocaleString() }}</span>
            </template>

            <template v-slot:body.append v-if="!carregando">
              <tr>
                <th>TOTAIS</th>
                <th class="text-right">{{ totais.alertas.toLocaleString() }}</th>
                <th class="text-right">{{ totais.correcoes.toLocaleString() }}</th>
                <th class="text-right">{{ totais.imagens.toLocaleString() }}</th>
                <th class="text-right">{{ totais.semOcr.toLocaleString() }}</th>
                <th class="text-right">{{ totais.leituras.toLocaleString() }}</th>
                <th class="text-right">{{ totais.placas.toLocaleString() }}</th>
              </tr>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import relatoriosApi from '@/api/relatorios'
  import formatarData from '@/utils/formatar-data-input'
  import dayjs from 'dayjs'

  export default {

    data () {
      return {
        carregando: false,
        linhas: [],
        menuDataInicio: false,
        menuDataFim: false,
        dataInicioFormatada: null,
        dataFimFormatada: null,
        filtros: {
          data_inicio: dayjs().startOf('month').format('YYYY-MM-DD'),
          data_fim: dayjs().endOf('month').format('YYYY-MM-DD'),
        },
        formatarData,
        cabecalho: [
          { text: 'Data', value: 'data_criacao' },
          { text: 'Alertas', value: 'alertas', align: 'end' },
          { text: 'Leituras Corrigidas', value: 'leiturasCorrigidas', align: 'end' },
          { text: 'Imagens', value: 'imagens', align: 'end' },
          { text: 'Imagens sem OCR', value: 'imagensSemOCR', align: 'end' },
          { text: 'Leituras', value: 'leituras', align: 'end' },
          { text: 'Placas', value: 'placas', align: 'end' },
        ],
        totais: {
          alertas:0,
          correcoes:0,
          imagens:0,
          semOcr:0,
          leituras:0,
          placas:0,
        }
      }
    },

    watch: {
      'filtros.data_inicio': {
        immediate: true,
        handler (data) {
          this.dataInicioFormatada = formatarData.ptBr(data)
        },
      },
      'filtros.data_fim': {
        immediate: true,
        handler (data) {
          this.dataFimFormatada = formatarData.ptBr(data)
        },
      },
    },

    methods: {
      zerarTotais() {
        this.totais = {
          alertas:0,
          correcoes:0,
          imagens:0,
          semOcr:0,
          leituras:0,
          placas:0
        }
      },

      async carregarRelatorio () {
        try {
          this.carregando = true
          var information = []
          this.linhas = []
          this.zerarTotais()

          const resposta = await relatoriosApi.estatisticas(this.filtros)

          resposta.data.leituras.totalLeiturasPordiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            let qtde = parseInt(item.totalLeituras ?? 0)

            information[item.data_criacao]['leituras'] = qtde
            this.totais.leituras += qtde
          })

          resposta.data.placas.totalPlacasPordiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            let qtde = parseInt(item.totalPlacas ?? 0)

            information[item.data_criacao]['placas'] = qtde
            this.totais.placas += qtde
          })

          resposta.data.imagens.totalImagensPordiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            let qtde = parseInt(item.totalImagens ?? 0)

            information[item.data_criacao]['imagens'] = qtde
            this.totais.imagens += qtde
          })

          resposta.data.imagensSemOCR.totalImagensSemOCRPorDiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            let qtde = parseInt(item.totalImagens ?? 0)

            information[item.data_criacao]['imagensSemOCR'] = qtde
            this.totais.semOcr += qtde
          })

          resposta.data.corrigidas.totalLeiturasComPlacaCorrigidaPorDiaNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            let qtde = parseInt(item.totalLeiturasCorrigidas ?? 0)

            information[item.data_criacao]['leiturasCorrigidas'] = qtde
            this.totais.correcoes += qtde
          })

          resposta.data.alertas.totalAlertasNoPeriodo.forEach((item) => {
            if (!information[item.data_criacao]) information[item.data_criacao] = []
            let qtde = parseInt(item.totalAlertas ?? 0)

            information[item.data_criacao]['alertas'] = qtde
            this.totais.alertas += qtde
          })

          Object.keys(information).forEach((key) => {
            information[key].data_criacao = dayjs(key).format('DD/MM/YYYY')
            information[key].alertas = information[key].alertas ?? 0
            this.linhas.push({...information[key]})
          })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      }
    },

    created() {
      this.carregarRelatorio()
    }
  }
</script>
